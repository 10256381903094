import React from "react"
import TheaterVideo from "@components/Theater/TheaterVideo"

import { graphql } from "gatsby"
import ResponsiveImage from "../components/ResponsiveImage"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import GetStarted from "../components/CTA/GetStarted"
import Text from "../components/Text"
import { Section, Columns } from "../components/Containers"

class iCatPage extends React.Component {
  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]
    return (
      <SharedStateProvider>
        <Layout
          pageTitle="i-cat-page"
          footerColorBack
          pageHeading={post.heading}>
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            pathname={this.props.location.pathname}
          />

          <Section>
            <Columns sideColumnsSize={5}>
              <div className="column">
                <Text className="joshua-tree-content" text={post.body} />
              </div>
            </Columns>
          </Section>

          <GetStarted
            className="get-started-cta three-d-cta color-back"
            centerHeading
            centerText
            headingSideColumnIs={5}
            sideColumnIs={4}
            heading={post.getStarted.heading}
            paragraph={post.getStarted.blurb}
            // buttonText={post.getStarted.buttonText}
            // buttonUrl={post.getStarted.href}
            buttons={post.getStarted.buttons}
          />
        </Layout>
      </SharedStateProvider>
    )
  }
}

export const pageQuery = graphql`
  query threeDImagingPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        blurb
        metaTitle
        metaDescription
        youtube
        imageId
        imageIdMobile
        body
        getStarted {
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
          blurb
          buttonText
          heading
          href
        }
      }
    }
  }
`

export default iCatPage
