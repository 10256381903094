import PropTypes from "prop-types"
import React from "react"

var classNames = require("classnames")

class CTA extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let ctaClasses = classNames("columns cta-columns", this.props.className)

    return (
      <div className={ctaClasses}>
        <div className="column">
          <div className="columns">{this.props.headingColumns}</div>
          <div className="columns">
            <div className={`column is-${this.props.sideColumnIs}`}></div>
            {this.props.textColumns}
            <div className={`column is-${this.props.sideColumnIs}`}></div>
          </div>
          <div className="columns has-text-centered is-centered">
            {this.props.buttonColumns}
          </div>
        </div>
      </div>
    )
  }
}

CTA.propTypes = {
  headingColumns: PropTypes.element,
  textColumns: PropTypes.element,
  buttonColumns: PropTypes.element,
  sideColumnIs: PropTypes.number
}

export default CTA
