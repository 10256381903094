import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"
import { Button, ButtonGroupMap } from "../Button"
import CTA from "./CTA"
import Text from "../Text"

class GetStarted extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let buttonColumns
    // One or multiple buttons
    if (this.props.buttons) {
      buttonColumns = (
        <div className="column">
          <ButtonGroupMap isCentered buttons={this.props.buttons} />
        </div>
      )
    } else {
      buttonColumns = (
        <div className="column">
          <Button
            contained
            href={this.props.buttonUrl ? this.props.buttonUrl : "undefined"}
            buttonText={
              this.props.buttonText ? this.props.buttonText : "undefined"
            }
          />
        </div>
      )
    }

    // CSS classes
    let headingClasses = classNames("column", {
      "has-text-centered": this.props.centerHeading
    })
    let textClasses = classNames("column", {
      "has-text-centered": this.props.centerText
    })

    return (
      <CTA
        className={this.props.className ? this.props.className : ""}
        sideColumnIs={this.props.sideColumnIs}
        headingSideColumnIs={this.props.headingSideColumnIs}
        headingColumns={
          <div className={headingClasses}>
            {this.props.subheading && (
              <Text as="h4" text={this.props.subheading} />
            )}
            <Text as="h3" text={this.props.heading} />
          </div>
        }
        textColumns={
          <Text className={textClasses} text={this.props.paragraph} />
        }
        buttonColumns={buttonColumns}
      />
    )
  }
}

GetStarted.propTypes = {
  className: PropTypes.string,
  centerHeading: PropTypes.bool,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  centerText: PropTypes.bool,
  paragraph: PropTypes.element,
  buttons: PropTypes.array,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string,
  sideColumnIs: PropTypes.number,
  headingSideColumnIs: PropTypes.number
}

export default GetStarted
